import React from "react";
import {EntryTakeScheduledMedicationsItem, TakeScheduledMedicationsEntry,} from "../../types/Entry";
import {useQuery} from "@tanstack/react-query";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";
import {NavLink} from "react-router-dom";
import {PillIcon} from "../../components/Icons/Icons";
import {IonIcon} from "@ionic/react";
import {timeOutline} from "ionicons/icons";
import {diffInMinutes, getDateObject} from "../../utils/DateTime";

interface MedsForDisplay {
    taken: Map<string, EntryTakeScheduledMedicationsItem[]>;
    skipped: EntryTakeScheduledMedicationsItem[];
    total: number;
}

export default function EntryTakeSchdeuledMedications(props: {
    variant: string,
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    switch (props.variant) {
        case "full":
            return <EntryTakeScheduledMedicationsFull id={props.id} time={props.time} data={props.data}/>;
        case "list-item":
        default:
            return <EntryTakeScheduledMedicationsListItem id={props.id} time={props.time} data={props.data}/>;
    }
}

function summary(data: TakeScheduledMedicationsEntry): JSX.Element {
    if (data.taken_count === 0 && data.skipped_count === 0) {
        return <>"No medications in this entry (this should never happen!)"</>;
    }

    let parts = [];
    if (data.taken_count > 0) {
        let takenEarly = 0, takenLate = 0;
        for (const item of data.items as EntryTakeScheduledMedicationsItem[]) {
            if (item.status === "taken") {
                let diff = diffInMinutes(getDateObject(data.date, data.time), getDateObject(item.date_taken, item.time_taken))
                if (diff < -30) {
										takenEarly++;
								} else if (diff > 30) {
                    takenLate++;
                }
            }
        }

        let earlyText = "", lateText = "";
        if (takenEarly > 0) {
            if (takenEarly === data.taken_count) {
                earlyText = ", all early";
            } else {
                earlyText = ", " + takenEarly + " early";
            }
        }
        if (takenLate > 0) {
            if (takenLate === data.taken_count) {
                lateText = ", all late";
            } else {
                lateText = ", " + takenLate + " late";
            }
        }
        parts.push(data.taken_count + " medication" + (data.taken_count === 1 ? "" : "s") + " taken" + earlyText + lateText);
    }
    if (data.skipped_count > 0) {
        if (data.taken_count > 0) {
            parts.push(<span style={{fontStyle: "italic"}}>{data.skipped_count + " skipped"}</span>);
        } else {
            parts.push(<span
                style={{fontStyle: "italic"}}>{data.skipped_count + " medication" + (data.skipped_count === 1 ? "" : "s") + " skipped"}</span>);
        }
    }
    if (data.pending_count > 0) {
        parts.push(<span className={"text-danger"}>{data.pending_count + " not yet taken"}</span>);
    }
    return <>{parts.map(((item, idx) => <div key={idx}>{item}</div>))}</>;
}

function EntryTakeScheduledMedicationsListItem(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    return <li>
        <NavLink to={"/entries/" + props.id} className="item">
            <div className="in" style={{display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                <div className={"text-secondary text-center me-1"}
                     style={{width: "2.75rem"}}>{props.time}</div>
                <div className={"me-1"}
                     style={{
                         marginTop: "2px",
                         width: "2rem",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "baseline"
                     }}>
                    <img src={require("../../components/Icons/assets/pill-duotone.svg").default} alt={"medication"}
                         style={{width: "1rem", height: "1rem", marginTop: "-2px"}}/>
                    <IonIcon icon={timeOutline} style={{fontSize: "1rem"}}/>
                </div>
                <div style={{flexGrow: 1}}>
                    {summary(props.data)}
                </div>
            </div>
        </NavLink>
    </li>;
}

function EntryTakeScheduledMedicationsFull(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry
}) {
    const {data} = props;
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    let firstTime = "";
    let displayItems: MedsForDisplay = {
        taken: new Map<string, EntryTakeScheduledMedicationsItem[]>(),
        skipped: [],
        total: 0
    };
    for (const item of data.items as EntryTakeScheduledMedicationsItem[]) {
        if (item.status === "skipped") {
            displayItems.skipped.push(item);
            displayItems.total++;
            continue
        }

        if (item.status !== "taken") {
            console.error("Unexpected entry med status: [" + item.medication_id + "] " + item.status);
        }

        let meds = displayItems.taken.get(item.time_taken);
        if (meds === undefined) {
            meds = [];
            displayItems.taken.set(item.time_taken, meds);
            if (firstTime === "") {
                firstTime = item.time_taken;
            }
        }

        meds.push(item);
        displayItems.total++;
    }

    return (
        <div className="card">
            <div className="card-body ps-0 pe-0 pb-0">
                <div className="ms-2 me-2" style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                    <img src={PillIcon} alt={"pill"} style={{width: "40px", height: "40px"}}/>
                    <div style={{flexGrow: 1}}>
                        <h2 className="card-title">Scheduled
                            medication{displayItems.total > 1 ? "s" : ""} taken</h2>
                    </div>
                </div>
                <div className="timeline timed mt-2"
                     style={{paddingBottom: 0, paddingTop: "8px", borderTop: "1px solid #ccc"}}>
                    {displayItems.taken.size > 0 && Array.from(displayItems.taken.entries()).map(([timeTaken, meds]) =>
                        <div key={timeTaken} className="item pb-1 text-dark mb-0">
                            <div className="time text-dark"
                                 style={{
                                     textAlign: "right",
                                     marginTop: timeTaken === firstTime ? "-3px" : "4px",
                                 }}>{timeTaken === firstTime && <>Taken at<br/></>}{timeTaken}</div>
                            <div className="dot bg-success" style={{marginTop: "4px"}}></div>
                            <div className="content">
                                <table>
                                    <tbody>
                                    {meds.map(med =>
                                        <tr key={med.medication_id}>
                                            <td style={{
                                                textAlign: "right",
                                                paddingRight: "0.5rem",
                                                minWidth: "4.5rem",
                                            }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                            <td>{med.name}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {displayItems.skipped.length > 0 &&
                        <div className="item pb-1 text-dark mb-0">
                            <div className="time text-dark"
                                 style={{
                                     textAlign: "right",
                                     marginTop: "4px",
                                 }}>Skipped
                            </div>
                            <div className="dot bg-warning" style={{marginTop: "4px"}}></div>
                            <div className="content">
                                <table>
                                    <tbody>
                                    {displayItems.skipped.map(med =>
                                        <tr key={med.medication_id}>
                                            <td style={{
                                                textAlign: "right",
                                                paddingRight: "0.5rem",
                                                minWidth: "4.5rem",
                                            }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                            <td>{med.name}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {data.notes !== "" &&
                        <div className="item pb-1">
                            <div className="time" style={{textAlign: "right"}}>Notes</div>
                            <div className="content" style={{
                                fontSize: "0.8rem",
                                lineHeight: "1.1rem",
                                paddingTop: "2px",
                            }}>{data.notes}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
