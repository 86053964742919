const pad = (num: number): string => num < 10 ? "0" + num : num.toString();

export const dateFromDate = (date: Date): string => {
    const month: number = date.getMonth() + 1;
    return date.getFullYear() + "-" + pad(month) + "-" + pad(date.getDate());
}

export const timeFromDate = (date: Date): string => {
    return pad(date.getHours()) + ":" + pad(date.getMinutes());
}

export const dateObjectFromString = (date: string): Date => new Date(Date.parse(date));

export const getCurrentDate = (): string => dateFromDate(new Date(Date.now()));

export const getCurrentTime = (): string => timeFromDate(new Date(Date.now()));

export const addDaysToDate = (date: Date, days: number): Date => {
    return new Date(Number(date) + (days * 86400000));
}

export const formatLongDate = (date: Date): string => {
    let bits: string[] = date.toDateString().split(" ");
    return bits[0] + ", " + bits[1] + " " + bits[2] + ", " + bits[3];
}

export const getDayOfWeek = (date: Date): string => {
    return date.toLocaleDateString('en-gb', {weekday: "long"})
}

export const getDaySuffix = (day: number): string => {
    return (day >= 10 && day <= 20) ? "th"
        : (day % 10 === 1 ? "st"
            : (day % 10 === 2 ? "nd"
                : (day % 10 === 3 ? "rd"
                    : "th")));
}

export const formatFriendlyDate = (date: Date, withDOW?: boolean): string => {
    let now = new Date();
    let localDate = new Date(date);
    now.setHours(0, 0, 0, 0);
    localDate.setHours(0, 0, 0, 0);
    let diff = daysBetweenDates(localDate, now);
    if (diff === 0) {
        return "Today" + (withDOW ? " (" + getDayOfWeek(localDate) + ")" : "");
    } else if (diff === -1) {
        return "Yesterday" + (withDOW ? " (" + getDayOfWeek(localDate) + ")" : "");
    } else {
        return formatReallyLongDate(localDate, !withDOW);
    }
}

export const formatReallyLongDate = (date: Date, hideDayOfWeek?: boolean, forceYear?: boolean): string => {
    let res = "";
    if (!hideDayOfWeek) res += date.toLocaleDateString('en-gb', {weekday: "long"}) + ", ";
    res += date.toLocaleDateString('en-gb', {month: "long"});
    let day = date.getDate();
    res += " " + day + getDaySuffix(day);
    if (forceYear || date.getFullYear() !== new Date().getFullYear()) {
        res += ", " + date.getFullYear();
    }
    return res;
}

export const getDateObject = (date: string, time: string) =>
    new Date(Date.parse(date + ((time && time.length > 0) ? "T" + time : "")));

export const daysBetweenDates = (date1: Date, date2: Date): number =>
    Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));

export const weeksBetweenDates = (date1: Date, date2: Date): number =>
    Math.ceil(daysBetweenDates(date1, date2) / 7);

export const validate24hTime = (time: string): boolean =>
    /^(^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$)$/.test(time);

export const timeToSeconds = (time: string): number => {
    let [h, m] = time.split(':');
    return (parseInt(h) * 3600) + (parseInt(m) * 60);
};

export const getClosestTime = (time: string, times: string[]): string => {
    let targetSecs = timeToSeconds(time);
    let closestSecs = timeToSeconds(times[0]);
    let closest = times[0];
    for (let i = 1; i < times.length; i++) {
        let secs = timeToSeconds(times[i]);
        if (Math.abs(secs - targetSecs) < Math.abs(closestSecs - targetSecs)) {
            closestSecs = secs;
            closest = times[i];
        }
    }
    return closest;
};

export const diffInMinutes = (date1: Date, date2: Date): number => {
    return Math.abs(date1.getTime() - date2.getTime()) / 60000;
};
