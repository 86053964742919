import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import {searchOutline} from "ionicons/icons";
import EntryDisplay from "./EntryDisplay";
import {dateFromDate, formatFriendlyDate, getDateObject} from "../../utils/DateTime";
import {useFetchEntriesInfiniteQuery} from "../../api/entries";
import LoadingWrapper from "../../components/Loader/LoadingWrapper";
import IntroScreen from "./IntroScreen";
import PullToRefreshWrapper from "../../components/PullToRefreshWrapper/PullToRefreshWrapper";
import {EprEntry} from "../../types/Entry";

function Entries(): JSX.Element {
    const entriesQuery = useFetchEntriesInfiniteQuery(10);
    const [entriesByDate, setEntriesByDate] = useState<{ [date: string]: EprEntry[] }>({});

    useEffect(() => {
        setEntriesByDate(() => {
            let newState: { [date: string]: EprEntry[] } = {}
            if (entriesQuery.data && entriesQuery.data.pages) {
                entriesQuery.data.pages.forEach((page, _) => {
                    page.entries.forEach((entry, _) => {
                        let entryDate = dateFromDate(new Date(Date.parse(entry.when)));
                        if (!newState[entryDate]) {
                            newState[entryDate] = [];
                        }
                        newState[entryDate].push(entry);
                    });
                });
            }
            return newState;
        })
    }, [entriesQuery.data]);

    return (<>
        <Header title={"Electronic Patient Record"}
                rightIcon={searchOutline} rightAction={() => undefined}/>
        <LoadingWrapper isLoading={entriesQuery.isLoading} hasError={entriesQuery.isError}
                        errorMessage={entriesQuery.isError ? entriesQuery.error.message : ""}
                        onRetry={entriesQuery.refetch}>
            {entriesQuery.data && entriesQuery.data.pages && entriesQuery.data.pages[0].entries ?
                <PullToRefreshWrapper onRefresh={entriesQuery.refetch}
                                      canFetchMore={entriesQuery.data.pages[entriesQuery.data.pages.length - 1].nextEntryWhen !== ""}
                                      onFetchMore={entriesQuery.fetchNextPage}><>
                    {Object.keys(entriesByDate).map((date, _) => <div key={date}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div className="listview-title"
                                 style={{flexGrow: 1}}>{formatFriendlyDate(getDateObject(date, "12:00"))}</div>
                        </div>
                        <ul className="listview link-listview mb-2">
                            {entriesByDate[date].map((entry, _) => <EntryDisplay key={entry.id} variant={"list-item"}
                                                                                 entry={entry}/>)}
                        </ul>
                    </div>)}
                </>
                </PullToRefreshWrapper>
                :
                <IntroScreen/>
            }
        </LoadingWrapper>
    </>);
}

export default Entries;
