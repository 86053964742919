import React, {useEffect} from "react";
import "./UpdateWidget.css";
import {appVersion} from "../../api/version";
import {IonIcon} from "@ionic/react";
import {arrowUpOutline} from "ionicons/icons";
import {fetchCurrentVersionOptions} from "../../api/build";
import {isProduction} from "../../api/base";
import {useQuery} from "@tanstack/react-query";

function UpdateWidget() {
    const [showUpgrading, setShowUpgrading] = React.useState(false);

    const {data: version, isLoading, isLoadingError, error} = useQuery({
        ...fetchCurrentVersionOptions,
        enabled: isProduction
    });

    useEffect(() => {
        if (isLoadingError) {
            console.error("Failed to fetch current version: " + error);
        }
    }, [isLoadingError, error]);

    const doUpgrade = () => {
        setInterval(() => window.location.href = "/?v=" + version, 2000);
        setShowUpgrading(true);
    };

    if (!isProduction) {
        return <></>;
    }

    return (<>
        {!isLoading && version !== appVersion && <>
            <div className="alert alert-outline-primary m-1" role="alert"
                 style={{
                     backgroundColor: "rgba(20,89,144,0.25)",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center"
                 }}>
                <IonIcon icon={arrowUpOutline} style={{fontSize: "3rem"}}/>
                <div style={{textAlign: "center", flexGrow: 1, lineHeight: "1.4rem", marginRight: "1rem"}}>
                    A new version is available: {version}
                </div>
                <button className="btn btn-primary text-nowrap" onClick={doUpgrade}>UPDATE APP</button>
            </div>
            {showUpgrading && <div className={"upgrading-sheet"}>
                <div className={"txt"}>Updating MyEPR...</div>
                <div className={"prg spinner-border text-secondary bg-primary"} role="status"/>
            </div>}
        </>}
    </>);
}

export default UpdateWidget;
