import axios from "axios";
import {getAxiosRequestOptions} from "./base";

const fetchCurrentVersion = async () => {
    const res = await axios.get(`https://myepr.app/version.txt`, getAxiosRequestOptions());
    return res.data.trim();
};

export const fetchCurrentVersionOptions = {
    queryKey: ["app", "current-version"],
    queryFn: fetchCurrentVersion,
    staleTime: 10 * (60 * 1000),
    gcTime: 10 * (60 * 1000),
};
