import Medication from "../../../../../types/Medication";

export const daysArray: Array<string> = ["", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const daysMap: Map<string, number> = new Map<string, number>();
daysMap.set("Sunday", 1);
daysMap.set("Monday", 2);
daysMap.set("Tuesday", 3);
daysMap.set("Wednesday", 4);
daysMap.set("Thursday", 5);
daysMap.set("Friday", 6);
daysMap.set("Saturday", 7);
export const Sunday: number = 1;
export const Monday: number = 2;
export const Tuesday: number = 3;
export const Wednesday: number = 4;
export const Thursday: number = 5;
export const Friday: number = 6;
export const Saturday: number = 7;

export const isDayEnabled = (days: number, day: number | undefined): boolean => {
    if (!day) return false;
    return (days & (1 << day)) !== 0;
}

export const summariseDays = (days: number): string => {
    if (days === 0) return "No schedule defined";
    if (days >= 254) {
        return "Every day";
    } else if (
        isDayEnabled(days, Monday) &&
        isDayEnabled(days, Tuesday) &&
        isDayEnabled(days, Wednesday) &&
        isDayEnabled(days, Thursday) &&
        isDayEnabled(days, Friday)
    ) {
        return "Every weekday";
    } else if (isDayEnabled(days, Saturday) && isDayEnabled(days, Sunday)) {
        return "Weekends";
    } else {
        let theFullDays: Array<string> = [];
        let theDays: Array<string> = [];
        daysArray.forEach((d, i) => {
            if (isDayEnabled(days, i)) {
                theFullDays.push(d);
                theDays.push(d.slice(0, 3));
            }
        })

        if (theDays.length === 0)
            return "No schedule defined";

        if (theDays.length === 1)
            return "Every " + theFullDays[0];

        let lastDay = theDays.pop();
        return theDays.join(", ") + " and " + lastDay;
    }
}

export const summariseSchedule = (medication: Medication): string => {
    let res = "";
    switch (medication.frequencyType) {
        case "regular":
            switch (medication.frequencyDetail) {
                case 1:
                    res = "Every day";
                    break;
                case 2:
                    res = "Every other day";
                    break;
                default:
                    res = "Every " + medication.frequencyDetail + " days";
                    break;
            }
            break;

        case "specific":
            res = summariseDays(medication.frequencyDetail!);
            break;

        case "as-needed":
            res = "As needed";
            break;

        case "never":
            res = "Not to be taken at this time";
            break;

        default:
            res = "No schedule defined";
            break;
    }
    return res;
}