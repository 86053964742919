import React, {useEffect} from "react";
import "./NumberEntryPad.css";
import {IonIcon} from "@ionic/react";
import {backspaceOutline} from "ionicons/icons";

const NumberEntryPad = (props: {
    currentValue: number;
    maxValue: number;
    overMaxMessage?: string;
    allowHalves: boolean;
    setFunction: (value: number) => void;
}) => {
    const {currentValue, maxValue, setFunction} = props;
    const buttonBackspace = -1;
    const buttonHalf = -2;
    const [value, setValue] = React.useState(props.currentValue);
    const [isChanged, setIsChanged] = React.useState(false);
    const [isHalf, setIsHalf] = React.useState(false);
    const [overMax, setOverMax] = React.useState(false);

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue]);

    useEffect(() => {
        setIsHalf(parseInt("" + value) !== value)
    }, [value]);

    useEffect(() => {
        setOverMax(value > maxValue);
    }, [value, maxValue]);

    const onButtonPressed = (b: number) => {
        let newValue = value;

        if (b === buttonBackspace) {
            if (isHalf) {
                newValue -= 0.5;
            } else {
                newValue = parseInt("" + (newValue / 10));
            }
        } else {
            if (!isChanged) {
                newValue = 0;
            }

            if (b === buttonHalf) {
                if (props.allowHalves) {
                    if (isHalf) {
                        newValue -= 0.5;
                    } else {
                        newValue += 0.5;
                    }
                }
            } else {
                newValue = (newValue * 10) + b;
            }
        }

        if (newValue >= 0) {
            setValue(newValue);
            setIsChanged(true);
        }
    };

    const reset = () => {
        setValue(currentValue);
        setIsChanged(false);
    };

    const onSet = () => {
        let v = value;
        reset();
        setFunction(v);
    };

    return (
        <div className="modal fade dialogbox" id={"number-entry-pad"} data-bs-backdrop="static" tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body m-0 p-2">
                        <div className="number-display"
                             style={{color: overMax ? "red" : isChanged ? "black" : "grey"}}>{value}</div>
                        <div className="over-max-message">{overMax ? props.overMaxMessage : ""}</div>
                        <div className="number-pad">
                            <button className="button1" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(1)}>1
                            </button>
                            <button className="button2" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(2)}>2
                            </button>
                            <button className="button3" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(3)}>3
                            </button>
                            <button className="button4" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(4)}>4
                            </button>
                            <button className="button5" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(5)}>5
                            </button>
                            <button className="button6" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(6)}>6
                            </button>
                            <button className="button7" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(7)}>7
                            </button>
                            <button className="button8" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(8)}>8
                            </button>
                            <button className="button9" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(9)}>9
                            </button>
                            {props.allowHalves &&
                                <button className="button-half" disabled={isChanged && isHalf}
                                        onClick={() => onButtonPressed(buttonHalf)}>{isHalf ? '.0' : '.5'}</button>}
                            <button className="button0" disabled={isHalf && isChanged}
                                    onClick={() => onButtonPressed(0)}>0
                            </button>
                            <button className="button-backspace" disabled={!isChanged || value === 0}
                                    onClick={() => onButtonPressed(buttonBackspace)}>
                                <IonIcon icon={backspaceOutline} className={"md hydrated m-0 p-0"}/>
                            </button>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <button className="btn btn-text-secondary" data-bs-dismiss="modal"
                                    onClick={reset}>CANCEL
                            </button>
                            <button className="btn btn-text-primary" data-bs-dismiss="modal"
                                    disabled={!isChanged || overMax} onClick={onSet}>SET
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default NumberEntryPad;
