import React, {useEffect} from "react";
import {EprEntry, TakeScheduledMedicationsEntry, TakeUnscheduledMedicationsEntry} from "../../types/Entry";
import EntryTakeScheduledMedications from "./EntryTakeScheduledMedications";
import EntryTakeUnscheduledMedications from "./EntryTakeUnscheduledMedications";
import {dateObjectFromString, timeFromDate} from "../../utils/DateTime";

export default function EntryDisplay(props: { variant: string, entry: EprEntry }) {
    const {variant, entry} = props;
    const [time, setTime] = React.useState<string>("");

    useEffect(() => {
        setTime(timeFromDate(dateObjectFromString(entry.when)));
    }, [entry]);

    switch (entry.type) {
        case "take-scheduled-medications":
            return <EntryTakeScheduledMedications variant={variant} id={entry.id} time={time}
                                                  data={JSON.parse(entry.content) as TakeScheduledMedicationsEntry}/>;

        case "take-unscheduled-medications":
            return <EntryTakeUnscheduledMedications variant={variant} id={entry.id} time={time}
                                                    data={JSON.parse(entry.content) as TakeUnscheduledMedicationsEntry}/>;

        default:
            return <div className="in">
                <div>
                    <header>{entry.type}</header>
                    Entry type is not supported
                    <footer>This issue has been reported.</footer>
                </div>
                <span className="text-muted">Edit</span>
            </div>;
    }
}
