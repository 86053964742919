import React from "react";
import {Link, NavLink} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {countPendingScheduledDoses} from "../../../api/medication-schedules";
import LoadingWrapper from "../../../components/Loader/LoadingWrapper";

function MedicationsCard(props: { today: string }): JSX.Element {
    const {today} = props;
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: doseTimes,
        error: loadingError,
        refetch,
    } = useQuery({
        queryKey: ["medications", "schedules", today],
        queryFn: () => countPendingScheduledDoses(today),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })

    return (
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    <div style={{display: "flex"}}>
                        <h5 className="card-title m-0" style={{lineHeight: "inherit"}}>Take medications</h5>
                        <div style={{textAlign: "right", flexGrow: 1}}>
                            <NavLink to={"/medications/manage"}
                                     className={"btn btn-sm btn-link"}>manage &gt;</NavLink>
                        </div>
                    </div>
                </div>
                <LoadingWrapper className={"mb-3"} isLoading={loading} hasError={isLoadingError}
                                errorMessage={loadingError ? loadingError.message : ""} onRetry={refetch}>
                    {doseTimes && doseTimes.length === 0 ?
                        <div className="card-body text-center pt-0">No medications available to be taken</div>
                        :
                        <ul className="listview link-listview">
                            {doseTimes && doseTimes.filter(entry => entry[1] > 0).map((entry) =>
                                <li key={entry[0]}>
                                    <Link to={"/medications/" + today + "/" + (entry[0])}>
                                        {entry[0].indexOf(':') === -1 ? "As needed" : entry[0]}
                                        <span className="badge badge-primary">{entry[1]}</span>
                                    </Link>
                                </li>)}
                        </ul>}
                </LoadingWrapper>
            </div>
        </div>
    );
}

export default MedicationsCard;
