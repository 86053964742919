import {QueryClient} from "@tanstack/react-query";
import {
    ScheduledDoseStatusPending,
    ScheduledDoseStatusSkipped,
    ScheduledDoseStatusTaken
} from "../types/MedicationSchedules";

export const queryKeyBase = ["take-medications", "pending"];
const queryKeyForDateTaken = [...queryKeyBase, "date-taken"];
const queryKeyForTimeTaken = [...queryKeyBase, "time-taken"];
const buildQueryKeyAtDateTime = (date: string, time: string) =>
    [...queryKeyBase, date, time];
const buildQueryKeyAtDateTimeForKey = (date: string, time: string, key: string) =>
    [...buildQueryKeyAtDateTime(date, time), key];
const buildQueryKeyAtDateTimeForMedication = (date: string, time: string, medicationId: string, key: string) =>
    [...buildQueryKeyAtDateTime(date, time), medicationId, key];
const buildQueryKeyAtDateTimeForMedicationAndVariant = (date: string, time: string, medicationId: string, strength: number, unit: string, form: string, key: string) =>
    [...buildQueryKeyAtDateTime(date, time), medicationId, strength, form, key];

export const fetchPendingTakeDateTakenOptions = {
    queryKey: queryKeyForDateTaken,
    queryFn: (): string => "now",
    initialValue: "now",
    cacheTime: 3600 * 1000,
    staleTime: 3600 * 1000,
    gcTime: 3600 * 1000,
    retry: 1,
    enabled: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
};

export const setPendingTakeDateTaken = async (queryClient: QueryClient, newDate: string) => {
    queryClient.setQueryData(queryKeyForDateTaken, newDate);
};

export const fetchPendingTakeTimeTakenOptions = {
    queryKey: queryKeyForTimeTaken,
    queryFn: (): string => "now",
    initialValue: "now",
    cacheTime: 3600 * 1000,
    staleTime: 3600 * 1000,
    gcTime: 3600 * 1000,
    retry: 1,
    enabled: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
};

export const setPendingTakeTimeTaken = async (queryClient: QueryClient, newTime: string) => {
    queryClient.setQueryData(queryKeyForTimeTaken, newTime);
};

export const fetchPendingTakeNotesOptionsFunc = (date: string, time: string, notes: string) => ({
    queryKey: buildQueryKeyAtDateTimeForKey(date, time, "notes"),
    queryFn: (): string => notes,
    initialValue: "",
    cacheTime: 3600 * 1000,
    staleTime: 3600 * 1000,
    gcTime: 3600 * 1000,
    retry: 1,
    enabled: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
});

export const setPendingTakeNotes = (queryClient: QueryClient, date: string, time: string, newNotes: string) => {
    return queryClient.setQueryData(buildQueryKeyAtDateTimeForKey(date, time, "notes"), newNotes);
};

export const fetchPendingTakeStatusQueryOptionsFunc = (date: string, time: string, medicationId: string) => ({
    queryKey: buildQueryKeyAtDateTimeForMedication(date, time, medicationId, "status"),
    queryFn: (): string => (time === "unscheduled" ? ScheduledDoseStatusPending : ScheduledDoseStatusTaken),
    initialValue: (time === "unscheduled" ? ScheduledDoseStatusPending : ScheduledDoseStatusTaken),
    cacheTime: 24 * (3600 * 1000),
    staleTime: 24 * (3600 * 1000),
    gcTime: 24 * (3600 * 1000),
    retry: 1,
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
});

export const setPendingTakeStatus = (queryClient: QueryClient, date: string, time: string, medicationId: string, newStatus: string) => {
    if (newStatus !== ScheduledDoseStatusPending && newStatus !== ScheduledDoseStatusSkipped && newStatus !== ScheduledDoseStatusTaken) {
        throw new Error("Invalid status");
    }
    queryClient.setQueryData(buildQueryKeyAtDateTimeForMedication(date, time, medicationId, "status"), newStatus);
};

export const fetchTakeStatusIsCustomisedQueryOptionsFunc = (date: string, time: string, medicationId: string) => ({
    queryKey: buildQueryKeyAtDateTimeForMedication(date, time, medicationId, "is-customised"),
    queryFn: (): boolean => (time === "unscheduled"),
    initialValue: (time === "unscheduled"),
    cacheTime: 24 * (3600 * 1000),
    staleTime: 24 * (3600 * 1000),
    gcTime: 24 * (3600 * 1000),
    retry: 1,
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
});

export const setTakeStatusIsCustomised = (queryClient: QueryClient, date: string, time: string, medicationId: string, isCustomised: boolean) => {
    queryClient.setQueryData(buildQueryKeyAtDateTimeForMedication(date, time, medicationId, "is-customised"), isCustomised);
};

export const fetchPendingTakeQuantityQueryOptionsFunc = (queryClient: QueryClient, date: string, time: string, medicationId: string, strength: number, unit: string, form: string, defaultValue: number) => {
    const queryKey = buildQueryKeyAtDateTimeForMedicationAndVariant(date, time, medicationId, strength, unit, form, "quantity");
    if (queryClient.getQueryData(queryKey) === undefined) {
        setPendingTakeQuantity(queryClient, date, time, medicationId, strength, unit, form, defaultValue);
    }
    return {
        queryKey: queryKey,
        queryFn: (): number => defaultValue,
        cacheTime: 3600 * 1000,
        staleTime: 3600 * 1000,
        gcTime: 3600 * 1000,
        retry: 1,
        enabled: false,
    };
}

export const setPendingTakeQuantity = async (queryClient: QueryClient, date: string, time: string, medicationId: string, strength: number, unit: string, form: string, newQuantityTaken: number) => {
    if (newQuantityTaken < 0) {
        throw new Error("Quantity cannot be negative");
    }
    queryClient.setQueryData(buildQueryKeyAtDateTimeForMedicationAndVariant(date, time, medicationId, strength, unit, form, "quantity"), newQuantityTaken);
};

export const invalidatePendingTakeCache = async (queryClient: QueryClient): Promise<void> => {
    return queryClient.invalidateQueries({queryKey: queryKeyBase});
};
