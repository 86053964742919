import React from 'react';
import HeaderWithBackButton from "../../components/Header/HeaderWithBackButton";
import {useParams} from "react-router-dom";
import {useFetchEntryQuery} from "../../api/entries";
import LoadingWrapper from "../../components/Loader/LoadingWrapper";
import EntryDisplay from "./EntryDisplay";
import {getEntryTitle} from "../../types/Entry";

function SingleEntry(): JSX.Element {
    const {id} = useParams<{ id: string }>();
    const entryQuery = useFetchEntryQuery(id || "");

    return (<>
        <HeaderWithBackButton
            title={entryQuery.data ? getEntryTitle(entryQuery.data) : entryQuery.isLoadingError ? "Something went wrong" : "Loading..."}/>
        <LoadingWrapper isLoading={entryQuery.isLoading} hasError={entryQuery.isLoadingError}
                        errorMessage={entryQuery.error ? entryQuery.error.message : ""} onRetry={entryQuery.refetch}>
            <div className={"section mt-2"}>
                <EntryDisplay variant={"full"} entry={entryQuery.data}/>
            </div>
        </LoadingWrapper>
    </>);
}

export default SingleEntry;
