import React, {useEffect, useState} from "react";
import randomString from "../../../../../utils/RandomString";
import Medication from "../../../../../types/Medication";
import IntField from "../../../../../components/Fields/IntField";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {invalidateMedicationsCache, saveMedication} from "../../../../../api/medications";
import {dismissModal} from "../../../../../utils/DismissModal";

function EditFrequencyActionSheet(props: { medication: Medication }): JSX.Element {
    const queryClient = useQueryClient();
    const {medication} = props;
    const [frequencyType, setFrequencyType] = useState<string>(medication.frequencyType!);
    const [frequencyDetail, setFrequencyDetail] = useState<number>(medication.frequencyDetail!);
    const randomIdRegular = randomString(8);
    const randomIdSpecific = randomString(8);
    const randomIdAsNeeded = randomString(8);
    const randomIdNever = randomString(8);
    const saveMedicationMutation = useMutation({
        mutationFn: () => saveMedication(queryClient, medication.medicationId!, medication.name, medication.unit,
            medication.notes!, frequencyType, frequencyDetail, medication.archived),
        onSuccess: (data: Medication) => {
            invalidateMedicationsCache(queryClient);
            dismissModal("edit-schedule");
        },
        retry: 3,
    });

    const resetForm = (e: any) => {
        setFrequencyType(medication.frequencyType!);
        setFrequencyDetail(medication.frequencyDetail!);
        e.preventDefault();
    }

    useEffect(() => {
        setFrequencyType(medication.frequencyType!);
        setFrequencyDetail(medication.frequencyDetail!);
    }, [medication]);

    return (
        <div className="modal fade action-sheet" id={"edit-schedule"} tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {saveMedicationMutation.isError && <div className="splash-page text-center mt-5 mb-5">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-danger">{saveMedicationMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            saveMedicationMutation.mutate();
                        }}>Try again
                        </button>
                    </div>}
                    {saveMedicationMutation.isPending && !saveMedicationMutation.isError &&
                        <h1 className={"splash-page text-center mt-5 mb-5"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>}
                    {!saveMedicationMutation.isPending && !saveMedicationMutation.isError && <>
                        <div className="modal-header">
                            <h5 className="modal-title">Dose frequency</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content" style={{height: "224px"}}>
                                <form>
                                    <div className="section mb-2 text-center">
                                        <div className="btn-group" role="group">
                                            <input type="radio" className="btn-check" name="frequencyRadioGroup"
                                                   id={randomIdRegular} checked={frequencyType === "regular"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           setFrequencyType("regular");
                                                           setFrequencyDetail(1);
                                                       }
                                                   }}/>
                                            <label className="btn btn-outline-primary ps-1 pe-1"
                                                   htmlFor={randomIdRegular}>Regular</label>

                                            <input type="radio" className="btn-check" name="frequencyRadioGroup"
                                                   id={randomIdSpecific} checked={frequencyType === "specific"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           setFrequencyType("specific");
                                                           setFrequencyDetail(0);
                                                       }
                                                   }}/>
                                            <label className="btn btn-outline-primary ps-1 pe-1"
                                                   htmlFor={randomIdSpecific}>Specific days</label>

                                            <input type="radio" className="btn-check" name="frequencyRadioGroup"
                                                   id={randomIdAsNeeded} checked={frequencyType === "as-needed"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           setFrequencyType("as-needed");
                                                           setFrequencyDetail(0);
                                                       }
                                                   }}/>
                                            <label className="btn btn-outline-primary ps-1 pe-1"
                                                   htmlFor={randomIdAsNeeded}>As needed</label>

                                            <input type="radio" className="btn-check" name="frequencyRadioGroup"
                                                   id={randomIdNever} checked={frequencyType === "never"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           setFrequencyType("never");
                                                           setFrequencyDetail(0);
                                                       }
                                                   }}/>
                                            <label className="btn btn-outline-primary ps-1 pe-1"
                                                   htmlFor={randomIdNever}>Never</label>
                                        </div>
                                    </div>
                                    <div style={{height: "80px"}}>
                                        {frequencyType === "regular" &&
                                            <IntField type={"interval"} label={""} value={frequencyDetail}
                                                      setFunction={setFrequencyDetail}/>}
                                        {frequencyType === "specific" &&
                                            <IntField type="days" label={""} value={frequencyDetail}
                                                      setFunction={setFrequencyDetail}/>}
                                        {frequencyType === "as-needed" &&
                                            <div className={"text-center pt-1"} style={{lineHeight: "1.4rem"}}>
                                                Medication will be available<br/>
                                                to be taken at any time
                                            </div>}
                                        {frequencyType === "never" &&
                                            <div className={"text-center pt-2"}>
                                                Medication will not be shown anywhere
                                            </div>}
                                    </div>
                                    <div style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                                                onClick={resetForm}>
                                            Cancel
                                        </button>
                                        <button className={"btn btn-primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    saveMedicationMutation.mutate();
                                                }}>Set frequency...
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default EditFrequencyActionSheet;
