import React, {useEffect, useState} from "react";
import {
    MedicationVariantSuggested,
    ScheduledDoseStatusPending,
    ScheduledDoseStatusSkipped,
    ScheduledDoseStatusTaken,
    ScheduleItem
} from "../../../types/MedicationSchedules";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {
    fetchPendingTakeStatusQueryOptionsFunc,
    fetchTakeStatusIsCustomisedQueryOptionsFunc,
    setPendingTakeStatus,
    setTakeStatusIsCustomised
} from "../../../api/medication-pending-take";
import TakeMedicationVariantItem from "./TakeMedicationVariantItem";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../../api/medication-options";

const TakeMedicationItem = (props: {
    date: string,
    time: string,
    entry: ScheduleItem,
    allowSkipped: boolean,
    setStatus: (status: string) => void,
    resetDosesTrigger: number,
}) => {
    const {setStatus, entry, resetDosesTrigger} = props;
    const queryClient = useQueryClient();
    const [accentColour, setAccentColour] = useState<string>("secondary");
    const [doses, setDoses] = useState<Map<number, number>>(new Map<number, number>());
    const {data: isCustomised, refetch: refetchIsCustomised} =
        useQuery(fetchTakeStatusIsCustomisedQueryOptionsFunc(props.date, props.time, entry.medicationId!));
    const {data: currentStatus, refetch: refetchCurrentStatus} =
        useQuery(fetchPendingTakeStatusQueryOptionsFunc(props.date, props.time, entry.medicationId!));
    const [availableVariants, setAvailableVariants] = useState<MedicationVariantSuggested[]>(entry.suggestedVariants);
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);
    const [totalDoseText, setTotalDoseText] = useState<JSX.Element>(
        <><span
            style={{fontWeight: "bolder"}}>{entry.medicationStrength}</span> {lookups ? optionSingularToPlural(lookups.units, entry.medicationStrength, entry.medicationUnit) : entry.medicationUnit}</>);

    useEffect(() => {
        setDoses(new Map<number, number>());
        void refetchIsCustomised();
        void refetchCurrentStatus();
    }, [resetDosesTrigger, refetchIsCustomised, refetchCurrentStatus]);

    useEffect(() => {
        // Filter the suggested variants to only those that can actually be taken to make up this dose.
        if (entry.suggestedVariants) {
            setAvailableVariants(() =>
                entry.suggestedVariants.filter((variant) =>
                    // Zero strength means as-needed dose
                    !entry.medicationStrength ||
                    // Make sure there is at least some stock...
                    (variant.stock > 0 &&
                        // ...and that the variant strength is less than or equal to the medication strength
                        (variant.canBeHalved ? variant.strength / 2 : variant.strength) <= entry.medicationStrength)));
        }
    }, [entry.suggestedVariants, entry.medicationStrength]);

    useEffect(() => {
        switch (currentStatus) {
            case ScheduledDoseStatusSkipped:
                setAccentColour("warning");
                break;
            case ScheduledDoseStatusTaken:
                setAccentColour("primary");
                break;
            default:
                setAccentColour("secondary");
                break;
        }

        setStatus(currentStatus!);

        let total = 0;
        doses.forEach((value: number) => total += value);
        if (total === 0 && !entry.medicationStrength) {
            setTotalDoseText(<></>);
        } else if (isCustomised && currentStatus === ScheduledDoseStatusTaken && total !== entry.medicationStrength) {
            setTotalDoseText(<>, <span className={"text-danger"} style={{textDecoration: "line-through"}}>
                    {entry.medicationStrength}
                </span> <span
                style={{fontWeight: "bolder"}}>{total}</span> {lookups ? optionSingularToPlural(lookups.units, total, entry.medicationUnit) : entry.medicationUnit}</>);
        } else {
            setTotalDoseText(<>, <span
                style={{fontWeight: "bolder"}}>{entry.medicationStrength}</span> {lookups ? optionSingularToPlural(lookups.units, total, entry.medicationUnit) : entry.medicationUnit}</>);
        }
    }, [isCustomised, currentStatus, doses, entry, lookups]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateDose = (idx: number, dose: number) => {
        setDoses(curr => {
            let newDoses = new Map<number, number>(curr);
            newDoses.set(idx, dose);
            return newDoses;
        });
    }

    return <li style={{display: "block"}}>
        <div className="item">
            <div className="in" style={{display: "flex"}}>
                <div style={{flexGrow: 1, margin: "auto 0 auto 0"}}>
                    {entry.medicationName}{totalDoseText}
                    {isCustomised ?
                        entry.medicationNotes !== "" ? <footer>{entry.medicationNotes}</footer> : <></>
                        : <footer
                            className={"text-dark"}>Take {availableVariants && availableVariants.map((variant, idx) =>
                            <span key={variant.strength + "-" + variant.form}>
                                {idx > 0 ? ", " : ""}{variant.suggestedQuantity}x {variant.strength} {lookups ? optionSingularToPlural(lookups.units, variant.strength, entry.medicationUnit) : entry.medicationUnit} {lookups ? optionSingularToPlural(lookups.forms, variant.suggestedQuantity, variant.form) : variant.form}
                            </span>)}
                        </footer>}
                </div>
                {(isCustomised || entry.status === ScheduledDoseStatusTaken) ?
                    <div className="btn-group" role="group" style={{margin: "auto 0 auto 0"}}>
                        <input type="radio" className="btn-check" name={entry.medicationId + "-state"}
                               id={entry.medicationId + "-state-off"}
                               checked={!currentStatus || currentStatus === ScheduledDoseStatusPending}
                               onChange={(e) =>
                                   e.target.checked &&
                                   setPendingTakeStatus(queryClient, props.date, props.time, entry.medicationId,
                                       ScheduledDoseStatusPending)}/>
                        <label className={"btn btn-sm btn-outline-" + accentColour} style={{padding: "0 0.75rem"}}
                               htmlFor={entry.medicationId + "-state-off"}>X</label>

                        {props.allowSkipped && <>
                            <input type="radio" className="btn-check" name={entry.medicationId + "-state"}
                                   id={entry.medicationId + "-state-skipped"}
                                   checked={currentStatus === ScheduledDoseStatusSkipped}
                                   onChange={(e) =>
                                       e.target.checked &&
                                       setPendingTakeStatus(queryClient, props.date, props.time, entry.medicationId,
                                           ScheduledDoseStatusSkipped)}/>
                            <label className={"btn btn-sm btn-outline-" + accentColour}
                                   htmlFor={entry.medicationId + "-state-skipped"}>Skip</label>
                        </>}

                        <input type="radio" className="btn-check" name={entry.medicationId + "-state"}
                               id={entry.medicationId + "-state-taken"}
                               disabled={!availableVariants}
                               checked={currentStatus === ScheduledDoseStatusTaken}
                               onChange={(e) =>
                                   e.target.checked &&
                                   setPendingTakeStatus(queryClient, props.date, props.time, entry.medicationId,
                                       ScheduledDoseStatusTaken)}/>
                        <label className={"btn btn-sm btn-outline-" + accentColour}
                               htmlFor={entry.medicationId + "-state-taken"}>{availableVariants ? "Take" : "No Stock"}</label>
                    </div>
                    : <button className={"btn btn-sm btn-secondary"}
                              onClick={() => setTakeStatusIsCustomised(queryClient, props.date, props.time, entry.medicationId, true)}>
                        Change
                    </button>}
            </div>
            {isCustomised && currentStatus === ScheduledDoseStatusTaken && availableVariants &&
                availableVariants.map((variant, idx) =>
                    <TakeMedicationVariantItem key={variant.strength + "-" + variant.form} className={"mt-1"}
                                               date={props.date} time={props.time}
                                               medicationId={entry.medicationId} entry={entry}
                                               variant={variant} allowSkipped={props.allowSkipped}
                                               updateDose={(dose: number) => updateDose(idx, dose)}
                                               resetDosesTrigger={props.resetDosesTrigger}/>)}
        </div>
    </li>;
}

export default TakeMedicationItem;
