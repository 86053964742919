import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {MedicationSchedule} from "../types/MedicationSchedules";
import {getCurrentDate} from "../utils/DateTime";
import {QueryClient} from "@tanstack/react-query";

export const countPendingScheduledDoses = async (forDate: string): Promise<[string, number][]> => {
    return axios.post(`${baseUrl}/medications/doses/list-times`, {date: forDate}, getAxiosRequestOptions()).then((res) => {
        let result = [];
        if (res.data && res.data["times"]) {
            for (let entry of res.data["times"]) {
                if (forDate !== getCurrentDate() && entry["time"] === "unscheduled") {
                    continue;
                }
                result.push([entry["time"], entry["pendingCount"]]);
            }
        }
        return result;
    }).catch(handleAxiosError("Failed to get dose details"));
};

export const fetchScheduleQueryFunc = (queryClient: QueryClient, date: string, time: string, enabled: boolean) => ({
    enabled: enabled,
    queryKey: ["medications", "schedules", date, time],
    queryFn: () => fetchSchedule(queryClient, date, time),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
});

export const fetchSchedule = async (queryClient: QueryClient, forDate: string, forTime: string): Promise<MedicationSchedule> => {
    return axios.post(`${baseUrl}/medications/schedule/read`, {
        date: forDate,
        time: forTime,
        withDoses: true,
        withVariants: true,
    }, getAxiosRequestOptions()).then((res) => {
        return res.data;
    }).catch(handleAxiosError("Failed to get scheduled doses"));
};
