import React from "react";
import {TakeScheduledMedicationsEntry, TakeUnscheduledMedicationsEntry} from "../../types/Entry";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";
import {useQuery} from "@tanstack/react-query";
import {NavLink} from "react-router-dom";
import {PillIcon} from "../../components/Icons/Icons";
import {IonIcon} from "@ionic/react";
import {flashOutline} from "ionicons/icons";

export default function EntryTakeUnscheduledMedications(props: {
    variant: string,
    id: string,
    time: string,
    data: TakeUnscheduledMedicationsEntry,
}) {
    switch (props.variant) {
        case "full":
            return <EntryTakeUnscheduledMedicationsFull id={props.id} time={props.time} data={props.data}/>;
        case "list-item":
        default:
            return <EntryTakeUnscheduledMedicationsListItem id={props.id} time={props.time} data={props.data}/>;
    }
}

function summary(data: TakeUnscheduledMedicationsEntry, units?: Map<string, string>): JSX.Element {
    if (data.items.length === 0) {
        return <>No medications in this entry (this should not happen!)</>;
    }

    let parts = [];
    for (let i = 0; i < data.items.length; i++) {
        let item = data.items[i];
        parts.push(item.strength + " " + (units ? optionSingularToPlural(units, item.strength, item.unit) : item.unit) + " of " + item.name);
    }

    return <>{parts.map(((item, idx) => <div key={idx}>{item}</div>))}</>;
}

function EntryTakeUnscheduledMedicationsListItem(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    return <li>
        <NavLink to={"/entries/" + props.id} className="item">
            <div className="in" style={{display: "flex"}}>
                <div className={"text-secondary text-center me-1"} style={{width: "2.75rem"}}>{props.time}</div>
                <div className={"me-1"}
                     style={{
                         marginTop: "2px",
                         width: "2rem",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "baseline"
                     }}>
                    <img src={require("../../components/Icons/assets/pill-duotone.svg").default} alt={"medication"}
                         style={{width: "1rem", height: "1rem", marginTop: "-2px"}}/>
                    <IonIcon icon={flashOutline} style={{fontSize: "1rem"}}/>
                </div>
                <div style={{flexGrow: 1}}>{summary(props.data, lookups ? lookups.units : undefined)}</div>
            </div>
            {/*<span className="text-muted">Edit</span>*/}
        </NavLink>
    </li>;
}

function EntryTakeUnscheduledMedicationsFull(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry
}) {
    const {data} = props;
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    if (data.items.length === 0) {
        return <></>;
    }

    return (
        <div className="card">
            <div className="card-body ps-0 pe-0 pb-0">
                <div className="ms-2 me-2" style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                    <img src={PillIcon} alt="medication dose"
                         style={{width: "40px", height: "40px"}}/>
                    <div style={{flexGrow: 1}}>
                        <h5 className="card-title">Medication{data.items.length !== 1 ? "s" : ""} taken</h5>
                    </div>
                </div>
                <div className="timeline timed mt-2"
                     style={{paddingBottom: 0, paddingTop: "8px", borderTop: "1px solid #ccc"}}>
                    <div className="item pb-1 text-dark mb-0">
                        <div className="time text-dark" style={{marginTop: "4px"}}>{props.time}</div>
                        <div className="dot bg-success" style={{marginTop: "4px"}}></div>
                        <div className="content">
                            <table>
                                <tbody>
                                {data.items.map(med =>
                                    <tr key={med.medication_id}>
                                        <td style={{
                                            textAlign: "right",
                                            paddingRight: "0.5rem",
                                            minWidth: "4.5rem",
                                        }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                        <td>{med.name}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {data.notes !== "" &&
                        <div className="item pb-1">
                            <div className="time" style={{textAlign: "right"}}>Notes</div>
                            <div className="content" style={{
                                fontSize: "0.8rem",
                                lineHeight: "1.1rem",
                                paddingTop: "2px",
                            }}>{data.notes}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
