import {dateObjectFromString, formatReallyLongDate, timeFromDate} from "../utils/DateTime";

export interface EprEntry {
    id: string;
    when: string;
    type: string;
    content: string;
    itemType: string;
    itemId: string;
}

export interface EprEntriesList {
    entries: EprEntry[];
    nextEntryWhen: string;
}

export interface EntryTakeScheduledMedicationsItem {
    date_taken: string;
    time_taken: string;
    medication_id: string;
    status: string;
    name: string;
    strength: number;
    unit: string;
}

export interface TakeScheduledMedicationsEntry {
    date: string;
    time: string;
    status: string;
    pending_count: number;
    skipped_count: number;
    taken_count: number;
    notes: string;
    items: EntryTakeScheduledMedicationsItem[];
}

export interface EntryTakeUnscheduledMedicationsItem {
    date_taken: string;
    time_taken: string;
    medication_id: string;
    status: string;
    name: string;
    strength: number;
    unit: string;
}

export interface TakeUnscheduledMedicationsEntry {
    date: string;
    time: string;
    status: string;
    pending_count: number;
    skipped_count: number;
    taken_count: number;
    notes: string;
    items: EntryTakeUnscheduledMedicationsItem[];
}

export const getEntryTitle = (entry: EprEntry): string => {
    let date = dateObjectFromString(entry.when);
    return formatReallyLongDate(date, true) + ", " + timeFromDate(date);
}
