import React from "react";
import "./Header.css";
import {IonIcon} from "@ionic/react";
import {useIsFetching, useQueryClient} from "@tanstack/react-query";
import HelpButton from "../HelpPanel/HelpButton";
import {invalidateMedicationsCache} from "../../api/medications";
import UpdateWidget from "../UpdateWidget/UpdateWidget";

function Header(props: {
    title: string,
    leftIcon?: any,
    leftAction?: any,
    leftModalId?: string,
    rightIcon?: any,
    rightAction?: any,
    rightModalId?: string,
    helpId?: string,
}) {
    const queryClient = useQueryClient();
    const isFetching = useIsFetching();

    return (<>
        <div className="appHeader bg-primary text-light">
            <div className="left">
                {props.leftIcon ?
                    <IonIcon icon={props.leftIcon} className={"md hydrated pointer"}
                             data-bs-toggle={props.leftModalId ? "modal" : ""}
                             data-bs-target={props.leftModalId ? "#" + props.leftModalId : ""}
                             onClick={() => {
                                 if (props.leftAction) props.leftAction();
                             }}/>
                    : <div style={{width: "26px"}}></div>}
                {isFetching > 0 &&
                    <div style={{position: "relative"}}>
                        <div style={{
                            position: "absolute",
                            left: "8px",
                            top: 0,
                            fontSize: "0.5rem"
                        }}>{isFetching}</div>
                        <div className="spinner-border" role="status"
                             style={{marginTop: "3px", width: "1.25rem", height: "1.25rem"}}/>
                    </div>}
            </div>
            <div className="pageTitle pointer" onClick={() => invalidateMedicationsCache(queryClient)}>
                {props.title.length > 0 ? props.title : "My EPR"}
            </div>
            <div className="right">
                {props.helpId &&
                    <HelpButton id={props.helpId!} size={"large"} className={"mt-1"}/>}
                {props.rightIcon ?
                    <IonIcon icon={props.rightIcon} className={"md hydrated pointer"}
                             data-bs-toggle={props.rightModalId ? "modal" : ""}
                             data-bs-target={props.rightModalId ? "#" + props.rightModalId : ""}
                             onClick={() => {
                                 if (props.rightAction) props.rightAction()
                             }}/>
                    : <div style={{width: "26px"}}></div>}
            </div>
        </div>
        <UpdateWidget/>
    </>);
}

export default Header;
